<template>
    <div>
      <div class="title"> <i class="el-icon-arrow-left icon" @click="to"></i>交易记录  </div>
       <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span>筛选条件</span>
          </div>
          <div  class="text">
              交易账户
             <el-select v-model="tran" placeholder="请选择" style="width:160px" clearable>
                <el-option
                    v-for="item in tranList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
             </el-select> 
           </div>
          <div  class="text">
              交易类型
             <el-select v-model="tranType" placeholder="请选择" style="width:160px" clearable>
                <el-option
                    v-for="item in tranTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
             </el-select> 
          </div>
          <div  class="text">
              收支类型
             <el-select v-model="iconme" placeholder="请选择" style="width:160px" clearable>
                <el-option
                    v-for="item in incomeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
             </el-select> 
          </div>  
          <div  class="text">
              交易时间
            <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
           </div>  
          <el-button type="primary" style="margin-left:40px;width:100px" @click="getDataList(1)">搜索</el-button>                          
      </el-card>
       <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span>交易记录</span>
          </div>
          <div>
    <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="serialNumber"
        label="交易流水号"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="accountName"
        label="交易账户"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="revenueExpenditureTypeName"
        width="130"
        label="收支类型">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="transactionTypeName"
        width="130"
        label="交易类型">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="transactionTime"
        label="交易时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="transactionAmount"
        label="交易金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="targetAccount"
        label="对方账号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="statusText"
        width="130"
        label="交易状态">
      </el-table-column>
    </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
          </div>
      </el-card>
    </div>
</template>
<script>
import {transaction,transactionType,incomeType,Records } from  "@/api/captalInterface";
export default {
    name: 'mian',
    components: {
       
    },
    data() {
      return {
        load:false,
       tranList:[],
       tran:"" ,//交易账户 
       incomeList:[],
       iconme:""  ,//收支
       tranTypeList:[],
       tranType:"",//交易类型
       value1:[],//日期
       tableData: [],
        pageSize: 10,
        totalCount: 0,//数据长度
        currPage: 1,
      }
    },
    created(){
        this.dropTran()
        this.getDataList()
    },
     methods:{
      to(){
          this.$router.go(-1)
      },
      dropTran(){
        transaction({}).then((data=>{
            if (data.code==200) {
            this.tranList=data.data
            }
        }))
         transactionType({}).then((data=>{
            if (data.code==200) {
            this.tranTypeList=data.data
            }
        }))
         incomeType({}).then((data=>{
            if (data.code==200) {
            this.incomeList=data.data
            }
        }))
      },
    // 列表
    getDataList(a){
        this.load=true
       if (a) {  //判断是不是搜索
          this.currPage=1 
          this.pageSize=10
        }
        if (this.value1.length!=0) {
          this.value1[0]=this.value1[0]+" 00:00:00"
           this.value1[1]=this.value1[1]+" 23:59:59"
        }
      Records({
        "current": this.currPage,
        "size": this.pageSize,
        "accountType":this.tran,//交易账户类型
        "transactionType":this.tranType,//交易类型
        "revenueExpenditureType":this.iconme,//收支类型
        "transactionStartTime":this.value1[0],
        "transactionEndTime":this.value1[1]
       }).then((data=>{
           this.load=false
          this.tableData=data.data.records
          this.totalCount=data.data.total
      }))
    },
     // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },

  }
}
</script>
<style scoped>
.text{
    font-size: 13px;
    display: inline-block;
    margin-left: 20px;
}
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.main .box-card{
    margin-top: 10px;
}
.clearfix span{
     font-size: 15px;
}
</style>
