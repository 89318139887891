import axios from '@/utils/http'
// -----------------资金 任务模块接口-------------

// 获取商户资金账户信息
export const merchantCapitalAccountInfo = ({}) => {
    return axios.request({
        url: '/capital/merchantCapitalAccountInfo',
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 获取商户交易记录
export const Records = ({transactionTimeType,accountType,transactionType,revenueExpenditureType,transactionStartTime,transactionEndTime,current,size,sidx,sortord}) => {
    return axios.request({
        url: '/capital/merchantCapitalTransactionRecords',
        data: {transactionTimeType,accountType,transactionType,revenueExpenditureType,transactionStartTime,transactionEndTime,current,size,sidx,sortord},
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 交易账户下拉框
export const transaction = ({}) => {
    return axios.request({
        url: '/capital/getPaymentCapitalDownBox',
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 交易类型下拉框
export const transactionType = ({}) => {
    return axios.request({
        url: '/capital/getTransactionTypeDownBox',
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 收支类型下拉框
export const incomeType = ({}) => {
    return axios.request({
        url: '/capital/getRevenueExpenditureTypeDownBox',
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 商户账户资金记录汇总
export const merchantSummaryInfo = (datavalue) => {
    return axios.request({
        url: '/capital/merchantSummaryInfo',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 账户充值--账户信息
export const merchantAccountInfo = () => {
    return axios.request({
        url: '/capital/merchantRechargeAccountInfo',
        params: "",
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}